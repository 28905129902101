<template>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
    <div class="container-fluid shadow-sm">
      <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
        <a id="backBtnForm" class="btn p-0 mr-md-4">
          <i class="fa fa-arrow-left text-light h5 mb-0"></i>
        </a>
        <span class="navbar-brand bg-transparent rounded p-0 text-center mr-0 mr-md-5">
          <span class="h6 font-weight-bold text-truncate">Stok Bahan - Tambah</span>
        </span>
        <a href="javascript:void(0);" class="btn px-0 d-block d-md-none">
          <i class="fa fa-home text-main"></i>
        </a>
      </nav>
    </div><!-- .container -->
  </header>

  <div class="container-fluid mt-header">
    <div class="row" id="body-sidemenu">
      <!-- MAIN -->
      <div id="main-content" class="col with-fixed-sidebar">

        <div class="row d-none">
          <div class="col-12">
            <div class="alert alert-danger bg-light mt-3 mb-0 alert-dismissible fade show" role="alert">
              <button type="button" class="close" data-dismiss="alert">&times;</button>
              <strong>Alert!</strong> You should <a href="javascript:void(0);" class="alert-link" title="Click to go to this message">read this message</a>.
            </div>
          </div> <!-- .col -->
        </div> <!-- .row -->

        <div class="row mt-4 mb-5">
          <div class="col-12">
            <form action="./jual-stok.html" class="mb-3 needs-validation" novalidate>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="nama">Nama Stok Bahan: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="nama" placeholder="Masukkan nama..." name="nama" value="" required autofocus>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="satuan">Satuan Penggunaan: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="satuan" placeholder="misal: gr, ml,..." name="satuan" value="gr" required>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="isi">Isi Sesuai Satuan Penggunaan: <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" id="isi" placeholder="misal: 1000" name="isi" value="1000" required>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="jumlah">Jumlah Cadangan Belum Terpakai: <span class="text-danger">*</span></label>
                    <input type="number" class="form-control" id="jumlah" placeholder="Masukkan jumlah pcs/pax cadangan belum terpakai..." name="jumlah" value="" required>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="harga">Harga Satuan: <span class="text-danger">*</span></label>
                    <input type="text" class="form-control currency" id="harga" placeholder="Masukkan harga satuan..." name="harga" value="" required>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="sel1">Supplier:</label>
                    <select class="form-control" id="sel1">
                      <option value="">Pilih Supplier...</option>
                      <option value="1">Supplier Daging</option>
                      <option value="2">Supplier Saos</option>
                      <option value="3">Supplier Snack</option>
                      <option value="4">Online Shop</option>
                    </select>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Pilih salah satu.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-12 col-md-6">
                  <label for="foto">Foto Bahan:<span class="text-danger ml-1">*</span></label>
                  <div class="custom-file mb-3">
                    <input type="file" class="custom-file-input form-control" id="upload" accept='image/*' required>
                    <label class="custom-file-label" for="upload" id="upload-label">Pilih gambar...</label>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                  <div class="image-area mb-4 w-50 mx-auto p-3">
                    <img id="imageResult" src="img/img.png" alt="" class="img-fluid rounded shadow-sm mx-auto d-block">
                  </div>
                </div> <!-- .col-* -->
              </div> <!-- .form-row -->

              <hr class="no-side-margin" />

              <div class="form-row mt-4">
                <div class="col-8 col-md-6">
                  <div class="form-group">
                    <label for="sel1">Produk:<span class="text-danger ml-1">*</span></label>
                    <select class="form-control form-control-sm" id="sel1" required>
                      <option value="">Pilih produk...</option>
                      <option value="1">Slice Yoshinoya Aus 500</option>
                      <option value="2">Daging Giling US 500</option>
                      <option value="3">Has Dalam Lokal 1kg</option>
                      <option value="4">Slice Yoshinoya Lokal 500</option>
                    </select>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Pilih salah satu.</div>
                  </div>
                </div> <!-- .col-* -->
                <div class="col-4 col-md-6">
                  <div class="form-group">
                    <label for="pemakaian">Pemakaian: <span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input type="number" class="form-control form-control-sm" id="jumlah" placeholder="Jumlah pemakaian..." name="jumlah" value="" required>
                      <div class="input-group-append">
                        <span class="input-group-text bg-transparent"><i class="fa fa-plus"></i></span>
                      </div>
                    </div>
                    <div class="valid-feedback">Valid.</div>
                    <div class="invalid-feedback">Isian ini wajib diisi.</div>
                  </div>
                </div> <!-- .col-* -->
              </div> <!-- .form-row -->

              <hr class="no-side-margin" />

              <div class="row mt-3">
                <div class="col text-center">
                  <button id="submitButton" type="submit" class="btn btn-main" title="Tambah"><i class="fa fa-plus mr-1"></i>Tambah</button>
                  <button type="reset" class="btn btn-light" title="Reset isian"><i class="fa fa-refresh mr-1"></i>Reset</button>
                  <a href="./jual-stok.html" class="btn btn-light" onclick="confirm('Anda yakin meninggalkan form isian ini?\nPerubahan tidak akan disimpan.');" title="Batal"><i class="fa fa-remove mr-1"></i>Batal</a>
                </div> <!-- .col-* -->
              </div> <!-- .row -->
            </form>
          </div><!-- .col -->
        </div><!-- .row -->
  
      </div><!-- main-content -->
    </div><!-- .row -->
  </div><!-- .container -->
</template>